import React, { useMemo } from 'react';
import { navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { accountProfileAddressesSelector } from 'state/account/account.selectors';
import { accountUpdateAddressToProfileRoutine } from 'state/account/account.routines';
import { AddressParts } from 'state/usps/usps.reducers';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import { ProfileObjectAddressPayload } from 'state/account/account.services';

import { useAddressVerification } from 'hooks/useAddressVerification';

import AddAddressForm, { AddressVerificationFailureModalContent } from 'components/add-address-form/AddAddressForm';
import { AddressCardProps } from 'components/shipping-addresses/address-card/address-card.props';
import ProfileLayout from 'components/layouts/profile/profile.layout';
import { FailureUpdateProfileModalContent } from '../intra-page-items/profile-update-modal.item';

export default function EditAddress({ location }: { location: any }) {
    const passedEditAddress = location.state as AddressCardProps;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const profileAddressesObject = useSelector(accountProfileAddressesSelector);
    const editAddressIndex = useMemo(
        () =>
            profileAddressesObject.findIndex(
                (address) =>
                    address.address1 === passedEditAddress.address1 &&
                    address.address2 === passedEditAddress.address2 &&
                    address.city === passedEditAddress.city &&
                    address.state === passedEditAddress.state &&
                    address.zipcode === passedEditAddress.zipcode + ''
            ),
        [profileAddressesObject]
    );
    const { verifyAddress } = useAddressVerification();

    if (editAddressIndex === -1 && typeof window !== 'undefined') {
        navigate('/secure/profile/shipping-address');
    }
    const formSubmit = (values: ProfileObjectAddressPayload) => {
        const address: AddressParts = {
            street1: values.address1,
            street2: values.address2,
            city: values.city,
            state: values.state,
            zip: values.zipcode
        };

        verifyAddress({
            address,
            onSuccess: () => {
                dispatch(
                    accountUpdateAddressToProfileRoutine({
                        index: editAddressIndex,
                        address: values,
                        onSuccess: () => {
                            navigate('/secure/profile/shipping-address');
                        },
                        onFailure: () => {
                            dispatch(
                                openModal({
                                    showClose: true,
                                    bodyContent: (
                                        <FailureUpdateProfileModalContent
                                            area={t('modals.updateProfile.areas.address')}
                                        />
                                    ),
                                    ctas: [
                                        {
                                            label: t('modals.updateProfile.labels.gotIt'),
                                            variant: 'primary',
                                            onClick: () => {
                                                dispatch(closeModal({}));
                                            }
                                        }
                                    ]
                                })
                            );
                        }
                    })
                );
            },
            onFailure: () => {
                dispatch(
                    openModal({
                        showClose: true,
                        bodyContent: <AddressVerificationFailureModalContent translation={t} />,
                        ctas: [
                            {
                                label: t('modals.addressVerificationFailure.submit'),
                                variant: 'primary',
                                onClick: () => {
                                    dispatch(closeModal({}));
                                }
                            }
                        ]
                    })
                );
            }
        });
    };
    const formCancel = () => {
        navigate('/secure/profile/shipping-address');
    };

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.eyebrowText`)}
            title={t(`pages.profile.shippingAddress.title`)}
            heading={'Shipping Address'}
        >
            <Container fluid>
                <AddAddressForm
                    defaultValues={profileAddressesObject[editAddressIndex]}
                    handleFormCancel={formCancel}
                    handleFormSubmit={formSubmit}
                />
            </Container>
        </ProfileLayout>
    );
}
